import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import MercadoLibreLogo from "../../../components/images/mercadolibre"
import SectionNavigate from '../../../components/widgets/sectionNavigate';
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import { IMG_Mercadolibre } from '../../../components/images/docs/mercadolibre';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyClavesDefault: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <MercadoLibreLogo />
            </div>
            <h2>
                Configurar Link de Autofacturación
            </h2>
            <h3>
                Configuración
            </h3>
            <ImageFooted explanation={`Dentro de "Mi configuración" podras seleccionar si deseas enviar el link después del pago o después de entregar el producto.`}>
                <IMG_Mercadolibre file={`configAutofact`} />
            </ImageFooted>
            <p>
                <b>El ícono de encendido define si la acción está prendida o apagada</b>, puedes darle click para encenderlo, volviéndose verde. O apagarlo, volviéndose rojo.
            </p>
            <ImageFooted explanation={`El listado de tus pedidos indicarán cuando se envió el link bajo la columna Link enviado, y en caso de error, se mostrará la razón aquí.`}>
                <IMG_Mercadolibre file={`ordenesLink`} />
            </ImageFooted>

            <h3>
                Casos de errores
            </h3>
            <p>
                MercadoLibre suele hacer cambios en su sistema de mensajería, comúnmente con la intención de reducir SPAM. Sin embargo, algunos casos pueden afectar que tu cliente reciba el link. 
                <b>Casos de errores al enviar link son poco probables, </b> pero a continuación detallamos algúnas razones que podrían causar que el cliente no reciba el mensaje:
            </p>
            <ul>
                <li>
                    <p><b>ML no permite que vendedor inicie mensajes para esta venta:</b> El 17 de Agosto 2020, mercadolibre implementó la siguiente regla de bloqueo <span className="error">La compra en su totalidad es de productos de Supermercado y el vendedor inicia la conversación.</span> <br />
                    Existen varias categorías de producto que MercadoLibre considera como productos de Supermercado, si bien no define públicamente cuales son, si un pedido cuenta con solo uno o más productos de este tipo, ML no permitirá que inicies el mensaje. <br />
                    <span className="quote small">Si este problema persiste y nuestros clientes se ven afectados, el link se enviará cuando el cliente te mande un mensaje con la palabra Factura, facturar o similar a partir de Septiembre 2020</span>
                    </p>
                </li>
                <li>
                    <p>
                        <b>Bloqueado por mediacion: </b> si un pedido esta en mediación, no se podrá enviar mensaje.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Bloqueado por intermediación: </b> Igual que el caso anterior, no se puede enviar mensaje cuando alguien de MercadoLibre interviene en la venta.
                    </p>
                </li>
                <li>
                    <p>
                        <b>En Envíos Full el producto debe llegar para enviar mensaje: </b> Productos de envío Full solo pueden recibir mensajes cuando son recibidos, en este caso, <b>Lo adecuado en envios full, es mandar el mensaje cuando llega el producto,</b> 
                        en este caso solamente el mensaje de saludo podría no llegar, pero el mensaje de facturación si, pues se manda cuando llega el producto.
                    </p>
                </li>
                <li>
                    <p>
                        <b>ML no permitió el mensaje por tiempo: </b> Este tipo de errores es MUY poco común, pues el límite de tiempo concluye dias después de recibir el pedido, y nosotros ya no enviamos mensajes después de entrega.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Bloqueado por problema de pago en ML: </b> Si tu tienda llegara a tener alguna deuda con MercadoLibre, es posible que no puedas enviar mensajes por este medio.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Bloqueado por problema de pago en ML: </b> Si tu tienda llegara a tener alguna deuda con MercadoLibre, es posible que no puedas enviar mensajes por este medio.
                    </p>
                </li>
                <li>
                    <p>
                        <b>No puede usar el servicio de mensajería de MercadoLibre: </b> Este es un mensaje muy genérico de MercadoLibre, puede estar asociado a nuevas reglas de bloqueo o a una configuración particular de tu tienda.
                    </p>
                </li>
            </ul>
            <SectionNavigate next={`/ecommerce/mercadolibre/link-comprador`} previous={`/ecommerce/mercadolibre/claves-default`}/>
        </div>
    </Layout>
)

export default ShopifyClavesDefault;